import { createApi } from "@reduxjs/toolkit/query/react";
import { transformPaginatedResponse, getErrorMessage } from "utils/api-utils";
import { baseQuery } from "./baseQuery";
import { ApiResponseHandler } from "./apiResponseHandler";
import { HttpStatusCode } from "./httpStatusCodes";

export const api = createApi({
  baseQuery,
  reducerPath: "servicesApi",
  tagTypes: [
    "ServiceHierarchy",
    "ServiceInput",
    "ServiceRatecard",
    "ServiceByBuilding",
  ], // used for auto refetching.
  endpoints: (build) => ({
    getServiceHierarchy: build.query({
      query: () => "services/hierarchy/",
      keepUnusedDataFor: 0,
      providesTags: ["ServiceHierarchy"],
      transformResponse: transformPaginatedResponse,
    }),
    getService: build.query({
      query: (parentId) => `services/${parentId}/`,
      transformResponse: transformPaginatedResponse,
    }),
    addService: build.mutation({
      query: (body) => ({
        url: `services/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ServiceHierarchy"],
    }),
    updateService: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `services/${id}/`,
        method: "PATCH",
        body: { id, ...rest },
      }),
      invalidatesTags: ["ServiceHierarchy"],
    }),
    getPricingInputs: build.query({
      query: (id) => `pricing-inputs/?service=${id}`,
      providesTags: ["ServiceInput"],
      transformResponse: transformPaginatedResponse,
    }),
    addPricingInput: build.mutation({
      query: (body) => ({
        url: "pricing-inputs/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ServiceInput"],
    }),
    updatePricingInput: build.mutation({
      query: ({ inputId, ...body }) => ({
        url: `pricing-inputs/${inputId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ServiceInput"],
    }),
    deletePricingInput: build.mutation({
      query: (inputId) => ({
        url: `pricing-inputs/${inputId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceInput"],
    }),
    getRatecards: build.query({
      query: (serviceId) => `rate-cards/?service=${serviceId}`,
      transformResponse: transformPaginatedResponse,
      providesTags: ["ServiceRatecard"],
    }),
    getRatecard: build.query({
      query: (cardId) => `rate-cards/${cardId}/`,
      providesTags: ["ServiceRatecard"],
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
    }),
    addRatecard: build.mutation({
      query: (body) => ({
        url: "rate-cards/",
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add Rate card!",
            () => getErrorMessage("adding a rate card", meta),
          ),
      }),
      invalidatesTags: ["ServiceRatecard"],
    }),
    updateRatecard: build.mutation({
      query: ({ cardId, ...body }) => ({
        url: `rate-cards/${cardId}/`,
        method: "PUT",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            response.status,
            "Failed to update Rate card!",
            () => getErrorMessage("updating a rate card", meta),
          ),
      }),
      invalidatesTags: ["ServiceRatecard"],
    }),
    deleteRatecard: build.mutation({
      query: (cardId) => ({
        url: `rate-cards/${cardId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceRatecard"],
      validateStatus: (response, meta) =>
        ApiResponseHandler.handleApiResponseStatus(
          HttpStatusCode.HTTP_204_NO_CONTENT,
          response.status,
          "Failed to delete Rate card!",
          () => getErrorMessage("deleting a rate card", meta),
        ),
    }),

    getRatecardColumnCategory: build.query({
      query: () => "rate-card-column-groups/",
      transformResponse: transformPaginatedResponse,
    }),

    getBuildingInfo: build.query({
      query: (clientRfx) =>
        `building_columns/${
          clientRfx ? `?client_rfx=${clientRfx}` : "standard/"
        }`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
    }),

    getCaltanaServiceHierarchy: build.query({
      query: () => "services/hierarchy/caltana/",
      transformResponse: transformPaginatedResponse,
    }),
  }),
});

export const {
  useGetServiceHierarchyQuery,
  useLazyGetServiceHierarchyQuery,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useGetServiceQuery,

  useGetPricingInputsQuery,
  useAddPricingInputMutation,
  useUpdatePricingInputMutation,
  useDeletePricingInputMutation,

  useGetRatecardsQuery,
  useGetRatecardQuery,
  useAddRatecardMutation,
  useUpdateRatecardMutation,
  useDeleteRatecardMutation,

  useGetRatecardColumnCategoryQuery,

  useGetBuildingInfoQuery,
  useGetCaltanaServiceHierarchyQuery,
} = api;
