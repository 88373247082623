export const STORAGE_KEY_USER_DATA = "user";
export const STORAGE_KEY_TOKEN = "token";
export const STORAGE_KEY_PAGE_TITLE = "current-page-title";

export function loadStoreValue(itemKey) {
  try {
    const serializedState = localStorage.getItem(itemKey);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export function saveStoreValue(itemKey, state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(itemKey, serializedState);
  } catch (e) {
    // ignore
  }
}

export function removeStoreValue(itemKey) {
  localStorage.removeItem(itemKey);
}
