import { createApi } from "@reduxjs/toolkit/query/react";
import { transformPaginatedResponse } from "utils/api-utils";
import { TreeItem } from "components/base-components/CheckTreeView/types";
import { baseQuery } from "./baseQuery";
import { ApiHierarchyService, HierarchyService } from "./types";
import { ApiService } from "./apiTypes";
import { Service } from "./projects";

export const api = createApi({
  baseQuery,
  reducerPath: "servicesTsApi",
  tagTypes: [
    "OwnServiceHierarchy",
    "ServiceHierarchy",
    "ProjectServiceMapping",
  ], // used for auto refetching.
  endpoints: (build) => ({
    getServiceHierarchy: build.query<TreeItem[], Record<string, never>>({
      query: () => "services/hierarchy/",
      keepUnusedDataFor: 0,
      providesTags: ["ServiceHierarchy"],
      transformResponse: transformPaginatedResponse,
    }),
    getOwnServiceHierarchy: build.query<TreeItem[], Record<string, never>>({
      query: () => "services/hierarchy/?onlyOwnServices=true",
      keepUnusedDataFor: 0,
      providesTags: ["OwnServiceHierarchy"],
      transformResponse: transformPaginatedResponse,
    }),
    getService: build.query<unknown, string>({
      query: (parentId) => `services/${parentId}/`,
      transformResponse: transformPaginatedResponse,
    }),
    addService: build.mutation<Service, ApiService>({
      query: (body) => ({
        url: `services/`,
        method: "POST",
        body,
      }),
      // manage this manually, as it closes the cell editor
      // on cache invalidation.
      // invalidatesTags: ["ServiceHierarchy"],
      transformResponse: (response: ApiService) => ({
        id: response.id,
        name: response.value,
        description: response.description,
        parent: response.parent,
      }),
    }),
    updateService: build.mutation<unknown, { id: string; unknown }>({
      query: ({ id, ...rest }) => ({
        url: `services/${id}/`,
        method: "PATCH",
        body: { id, ...rest },
      }),
      invalidatesTags: ["ServiceHierarchy", "OwnServiceHierarchy"],
    }),

    getCaltanaServiceHierarchy: build.query({
      query: () => "services/hierarchy/caltana/",
      transformResponse: transformPaginatedResponse,
    }),
    getProjectServiceHierarchy: build.query<
      HierarchyService[],
      { rfxId: string }
    >({
      query: ({ rfxId }) => `client_rfxs/${rfxId}/services/`,
      transformResponse: (response: ApiHierarchyService[]) =>
        response.map((service) => ({
          id: service.id,
          name: service.name,
          type: service.type,
          organization: service.organization,
          subServices: service.sub_services,
        })),
      providesTags: ["ProjectServiceMapping"],
    }),
    updateProjectServiceMapping: build.mutation<
      ApiHierarchyService,
      { projectId: number; parentServiceId: number; subServiceIds: number[] }
    >({
      query: ({ projectId, parentServiceId, subServiceIds }) => ({
        url: `client_rfxs/${projectId}/services/${parentServiceId}/`,
        method: "PATCH",
        body: { sub_services: subServiceIds },
      }),
      invalidatesTags: ["ProjectServiceMapping"],
    }),
  }),
});

export const {
  useGetServiceHierarchyQuery,
  useLazyGetServiceHierarchyQuery,
  useGetOwnServiceHierarchyQuery,
  useLazyGetOwnServiceHierarchyQuery,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useGetServiceQuery,

  useGetCaltanaServiceHierarchyQuery,
  useGetProjectServiceHierarchyQuery,
  useUpdateProjectServiceMappingMutation,
} = api;
