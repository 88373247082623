import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import {
  API_RESPONSE_LIMIT,
  transformPaginatedResponseWithCount,
} from "utils/api-util";
import { baseQuery } from "./baseQuery";
import {
  ApiBuildingColumn,
  ExistingApiObject,
  GetRestPursuitBuildingsParams,
  ListResponse,
  ListResult,
  PaginatedRfxQueryParams,
} from "./types";
import { ApiBuilding } from "./apiTypes";

const paginatedQuery = (url: string, offset = 0, limit = API_RESPONSE_LIMIT) =>
  `${url}?limit=${limit}&offset=${offset}`;

const inlineBuildingExtraInfoFromResponse = ({
  results,
  ...responseRest
}: ListResult<ApiBuilding>) => ({
  results: results.map((building) => {
    const { extra_info: extraInfo, ...rest } = building;
    return {
      ...extraInfo,
      ...rest,
    };
  }),
  ...responseRest,
});

const reducePromisesToDataOrError = <T>(
  responses: PromiseSettledResult<
    QueryReturnValue<unknown, FetchBaseQueryError, Record<string, unknown>>
  >[],
) => {
  responses as PromiseSettledResult<
    QueryReturnValue<T, FetchBaseQueryError, Record<string, unknown>>
  >[];
  const rejectedErrors = responses
    .filter((response) => response.status === "rejected")
    .map(
      (response) =>
        response.status === "rejected" && {
          statusText: response.reason as string,
          status: 500,
          data: response.reason as string,
        },
    );
  const fulfilledErrors = responses
    .filter(
      (response) => response.status === "fulfilled" && response.value.error,
    )
    .map(
      (response) =>
        response.status === "fulfilled" &&
        response.value.error && {
          statusText: response.value.error.status,
          status: 400,
          data: response.value.error.data,
        },
    );
  const errors = [...rejectedErrors, ...fulfilledErrors];

  if (errors.length > 0) {
    return {
      error: {
        status: errors[0].status,
        statusText: errors.map((error) => error.statusText).join(", "),
        data: errors.map((error) => error.data).join(", "),
      } as FetchBaseQueryError,
    };
  }
  const data = responses
    .filter((response) => response.status === "fulfilled")
    .map(
      (response) =>
        response.status === "fulfilled" && (response.value.data as T),
    );

  return { data };
};

type BuildingTagTypes =
  | "PursuitBuildingColumns"
  | "PursuitBuildings"
  | "PursuitBuildingPricings";

const invalidatesTagList = (
  type: BuildingTagTypes,
  buildings: ExistingApiObject[] = [],
) => [...buildings.map(({ id }) => ({ type, id }))];

const providesTagList = (
  type: BuildingTagTypes,
  buildings: ApiBuilding[] = [],
) => [
  ...buildings.map(({ id }) => ({ type, id: id.toString() })),
  { type, id: "LIST" },
];

export const api = createApi({
  baseQuery,
  reducerPath: "buildingsApi",
  tagTypes: [
    "PursuitBuildingColumns",
    "PursuitBuildings",
    "PursuitBuildingPricings",
  ],
  endpoints: (build) => ({
    // Buildings
    getRestPursuitBuildings: build.query<
      ListResult<ApiBuilding>,
      GetRestPursuitBuildingsParams
    >({
      async queryFn(arg, _, __, apiBaseQuery) {
        const {
          rfxId,
          count,
          limit: baseLimit = API_RESPONSE_LIMIT,
          offset: customOffset,
        } = arg;
        const pages = Math.ceil(count / baseLimit);
        let limit: number;
        let offset: number;
        const pageNumbers = Array.from(
          { length: customOffset === 0 ? pages : pages - 1 },
          (_val, i) => i + 1,
        );
        const promises = pageNumbers.map((page) => {
          limit = page === pages - 1 ? count - page * baseLimit : baseLimit;
          offset = (customOffset === 0 ? page - 1 : page) * baseLimit;
          return Promise.resolve(
            apiBaseQuery({
              url: paginatedQuery(
                `client_rfxs/${rfxId}/buildings/`,
                offset,
                limit,
              ),
            }),
          );
        });
        const responses = await Promise.allSettled(promises);
        const nestedDataArrays =
          reducePromisesToDataOrError<ListResponse<ApiBuilding[]>>(responses);

        if (nestedDataArrays.error) {
          return {
            error: nestedDataArrays.error,
          };
        }

        const data = nestedDataArrays.data
          ?.flatMap((record) => record.results)
          .flat();
        return {
          data: inlineBuildingExtraInfoFromResponse({
            results: data,
            count: data?.length || 0,
          }),
        };
      },
      keepUnusedDataFor: 30,
      providesTags: (result) =>
        providesTagList("PursuitBuildings", result?.results),
    }),
    listPursuitBuildings: build.query<
      ListResult<ApiBuilding>,
      PaginatedRfxQueryParams
    >({
      query: ({ rfxId, offset = 0, limit = API_RESPONSE_LIMIT }) =>
        paginatedQuery(`client_rfxs/${rfxId}/buildings/`, offset, limit),
      transformResponse: (response: ListResponse<ApiBuilding>) => {
        const inlinedExtraInfoResponse =
          inlineBuildingExtraInfoFromResponse(response);
        return transformPaginatedResponseWithCount(inlinedExtraInfoResponse);
      },
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        providesTagList("PursuitBuildings", result?.results),
    }),
    listBuildingsWithPricing: build.query<
      ListResult<ApiBuilding>,
      PaginatedRfxQueryParams
    >({
      query: ({ rfxId, offset = 0, limit = API_RESPONSE_LIMIT }) =>
        `client_rfxs/${rfxId}/buildings/?expansion=true&limit=${limit}&offset=${offset}`,
      transformResponse: (response: ListResponse<ApiBuilding>) => {
        const inlinedExtraInfoResponse =
          inlineBuildingExtraInfoFromResponse(response);
        return transformPaginatedResponseWithCount(inlinedExtraInfoResponse);
      },
      keepUnusedDataFor: 30,
      providesTags: (result) =>
        providesTagList("PursuitBuildingPricings", result?.results),
    }),
    addBuildings: build.mutation<ApiBuilding[], Omit<ApiBuilding, "id">[]>({
      async queryFn(buildings, _, __, apiBaseQuery) {
        if (buildings.length === 0) {
          return {
            error: {
              status: 500,
              statusText: "Internal Server Error",
              data: "Invalid ID provided.",
            },
          };
        }
        const promises = buildings.map((building) =>
          Promise.resolve(
            apiBaseQuery({
              url: `buildings/`,
              method: "POST",
              body: building,
            }),
          ),
        );

        const responses = await Promise.allSettled(promises);
        return reducePromisesToDataOrError<ApiBuilding>(responses);
      },
    }),
    updateBuildings: build.mutation<ApiBuilding[], ApiBuilding[]>({
      async queryFn(buildings, _, __, apiBaseQuery) {
        const promises = buildings
          .filter((building) => building !== undefined)
          .map(({ id, ...building }) =>
            Promise.resolve(
              apiBaseQuery({
                url: `buildings/${id}/`,
                method: "PATCH",
                body: {
                  ...building,
                },
              }),
            ),
          );
        const responses = await Promise.allSettled(promises);
        return reducePromisesToDataOrError<ApiBuilding>(responses);
      },
      invalidatesTags: (result) => [
        ...invalidatesTagList("PursuitBuildings", result),
        ...invalidatesTagList("PursuitBuildingPricings", result),
      ],
    }),
    deleteBuildings: build.mutation<ExistingApiObject[], number[]>({
      async queryFn(buildingIds, _, __, apiBaseQuery) {
        const promises = buildingIds.map((id) =>
          Promise.resolve(
            apiBaseQuery({
              url: `buildings/${id}/`,
              method: "DELETE",
            }),
          ),
        );
        const responses = await Promise.allSettled(promises);

        const non204Responses = responses
          .filter(
            (response) =>
              response.status === "rejected" || response.value.error,
          )
          .map(
            (response) =>
              response.status === "rejected" && {
                statusText: response.reason as string,
                status: 400,
                data: response.reason as string,
              },
          );

        if (non204Responses.length > 0) {
          return {
            error: {
              status: non204Responses[0].status,
              statusText: non204Responses
                .map((error) => error.statusText)
                .join(", "),
              data: non204Responses.map((error) => error.data).join(", "),
            } as FetchBaseQueryError,
          };
        }

        return { data: buildingIds.map((id) => ({ id })) };
      },
      invalidatesTags: (result) => [
        ...invalidatesTagList("PursuitBuildings", result),
        ...invalidatesTagList("PursuitBuildingPricings", result),
      ],
    }),
    // Building Columns
    listPursuitBuildingColumns: build.query<
      ListResult<ApiBuildingColumn>,
      PaginatedRfxQueryParams
    >({
      query: ({
        rfxId,
        offset = 0,
        limit = API_RESPONSE_LIMIT,
      }: PaginatedRfxQueryParams) =>
        `building_columns/?client_rfx=${rfxId}&limit=${limit}&offset=${offset}`,
      providesTags: ["PursuitBuildingColumns"],
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponseWithCount,
    }),
  }),
});

export const {
  useDeleteBuildingsMutation,
  useUpdateBuildingsMutation,
  useGetRestPursuitBuildingsQuery,
  useListPursuitBuildingsQuery,
  useListBuildingsWithPricingQuery,
  useLazyListPursuitBuildingsQuery,
  useListPursuitBuildingColumnsQuery,
  useAddBuildingsMutation,
} = api;
